<template>
  <y-layout-body-menu aside-width="7rem" :menu="[
    {title:'基础配置',path:'/card/plugin/wholesaler/conf'},
    {title:'规则配置',path:'/card/plugin/wholesaler/mode-rule'},
    {title:'批发商管理',path:'/card/plugin/wholesaler/user'},
    {title:'激活卡订单',path:'/card/plugin/wholesaler/order'},
  ]">
    <router-view/>
  </y-layout-body-menu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";

export default {
  name: "index",
  components: {YLayoutBodyMenu},
}
</script>

<style scoped>

</style>